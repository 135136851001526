<template>
  <payment-methods :value="obCompany" />
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import CompaniesMixin from "@/modules/companies/mixins/CompaniesMixin";
import PaymentMethods from "@/modules/companies/components/tabs/PaymentMethods.vue";
import { AppModule } from "@/store/app";

@Component({
  components: { PaymentMethods },
})
export default class CompanyPaymentMethods extends Mixins(CompaniesMixin) {
  get obCompany() {
    return AppModule.company;
  }
}
</script>
